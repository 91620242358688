import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import qs from 'query-string';
import { subject } from '@casl/ability';

import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { AbilityActions, AbilitySubjects, useAbilityCtx } from 'src/roleAccesses';

import {
    CheckboxItem,
    ThemeCircularProgress,
    ThemeLoader,
    ThemeOutlineInput,
    ThemePhoneNumberInput,
    ThemeSelectInput,
    ThemeSwitcher,
} from 'src/theming';

import { UserEditingFormWithBranchCard } from './user-editing-form-with-branch-card';

import { UserEditingFormInternalCard } from './user-editing-form-internal-card';
import { UserEditFormClientConnectionCard } from './user-edit-form-client-connection-card';

import {
    AppInstances,
    CUSTOMER_ROLES,
    INTERNAL_ROLES,
    USER_GROUPS,
} from 'src/shared/constants';
import { appInstance } from 'src/environment';
import { useAuth } from 'src/user-management/context-auth';
import { UserAutomaticCustomersAssigning } from './user-automatic-customers-assigning';

import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
    useStyle,
} from './user-editing-form-styles';

import {
    generateUserEditFormRoleSelectOptions,
    generateUsersEditFormGroupSelectOptions,
} from './user-edit-form-configs';

import {
    userEditFormValidationSchema,
    validateCardsWithBranches,
    validateClientConnectionsCard,
    validateInternalClientCard,
} from './user-edit-form-validation-schema';

import { userEditFormPreparingDataForRequest } from './user-edit-form-preparing-data-for-request';

import { getUserFormatter } from './user-edit-form-get-user-formatter';
import { UserEditFormApproveDeletingModalWindow } from './user-edit-form-approve-deleting-modal-window';
import UserEditFormRequestBody from './user-edit-form-request-body';

import {
    IClientConnections,
    InternalClientCard,
    UserClientsServerData,
    UserEditFormRequestTypes,
    UserEditingFormData,
    UserGetResponseBody,
    WithBranchFormCard,
} from './user-edit-form-types';

import { userEditFormBuildDataForCardWithBranches } from './user-edit-form-build-data-for-card-with-branches';
import UserActivation from './user-edit-form-activation';
import { userEditFormRenderRoleFields } from './user-editing-form-render-roles-field';
import { UserManagementSectionResponseBody } from '../uset-management-section/user-management-section-response-body';
import { AddUserMode } from 'src/user-management/uset-management-section/user-management-section';
import DeleteIcon from '@material-ui/icons/Delete';
import { Confirmation } from 'src/lib/confirmation-popup/confirmation';

interface UserEditingFormProps {
    setNewUser: (value: boolean) => void;
    setCreateUserMode: (value: AddUserMode) => void;
    activeUser?: string;
    createUserMode: AddUserMode;
    makeRequestForUsersList: () => Promise<UserManagementSectionResponseBody>;
    handleAddNewUser: () => void;
    forcedClientConnections?: CheckboxItem[] | undefined;
    handleChangeAccountOwner: (activeClientConnections: CheckboxItem[]) => void;
    setMenuListDisabled: (value: boolean) => void;
    setActiveUser: (value: string) => void;
}

export const UserEditingForm = (props: UserEditingFormProps): JSX.Element => {
    const {
        activeUser,
        setNewUser,
        setCreateUserMode,
        makeRequestForUsersList,
        handleAddNewUser,
        createUserMode,
        handleChangeAccountOwner,
        setMenuListDisabled,
        setActiveUser,
        forcedClientConnections,
    } = props;
    const classes = useStyle();
    const formControlClasses = useFormControlStyles();
    const inputClasses = useInputStyles();
    const formControlLabelClasses = useFormControlLabelStyles();

    const { enqueueSnackbar } = useSnackbar();
    const ability = useAbilityCtx();

    const httpClient = useHttpClient();

    const {
        userData: {
            userInfo: { id: userId },
        },
        getUserData,
    } = useAuth();

    const { t } = useTranslation(['common', 'user-management', 'errors']);

    const [editMode, setEditMode] = useState(false);
    const [isDeleteAccountOwnerOpen, setIsDeleteAccountOwnerOpen] = useState(false);
    const [isSetAccountOwnerActiveStatusOpen, setIsSetAccountOwnerActiveStatusOpen] =
        useState(false);
    const [isMutateLoading, setMutateLoading] = useState(false);
    const [isGetUserDataRequestLoading, setGetUserDataRequestLoading] = useState(false);
    const [internalClientCards, setInternalClientCards] = useState(null);
    const [hasTwoFactorAuth, setHasTwoFactorAuth] = useState(false);
    const [clientConnections, setClientConnections] = useState<CheckboxItem[]>([]);
    const [initialStateOfCardsWithBranches, setInitialStateOfCardsWithBranches] =
        useState<IClientConnections[] | void>([]);
    const [isEmailFieldFocused, setEmailFieldFocus] = useState(false);
    const [isCustomerAdvisorNumberFocused, setCustomerAdvisorFocused] =
        useState<boolean>(false);
    const [cardsWithBranches, setCardsWithBranches] = useState(null);
    const [isAllCustomerConnectionsAreAssigned, setAllCustomerConnectionsAssined] =
        useState<boolean>(false);

    const {
        watch,
        setValue,
        reset,
        formState: { isValid, errors },
        trigger,
        handleSubmit,
        control,
    } = useForm<UserEditingFormData>({
        mode: 'onChange',
        resolver: yupResolver(userEditFormValidationSchema),
        defaultValues: {
            name: '',
            surname: '',
            email: '',
            phoneNumber: '',
            userGroup: '',
            active: true,
            roles: [],
            customerAdvisorNumber: '',
            addNewCustomersAutomatically: null,
        },
    });

    const { fields: roleFields } = useFieldArray({ control, name: 'roles' });

    const roles = useMemo(() => roleFields?.map((role) => role.name) || [], [roleFields]);

    const [
        activated,
        id,
        name,
        surname,
        email,
        userGroup,
        active,
        phoneNumber,
        chosenClientConnections,
        customerAdvisorNumber,
        addNewCustomersAutomatically,
    ] = watch([
        'activated',
        'id',
        'name',
        'surname',
        'email',
        'userGroup',
        'active',
        'phoneNumber',
        'chosenClientConnections',
        'customerAdvisorNumber',
        'addNewCustomersAutomatically',
    ]);

    const accessToEditForm = useMemo(() => {
        return !roles.some((role) =>
            ability.cannot(AbilityActions.edit, subject(role, { id: activeUser }))
        );
    }, [ability, roles]);

    const isAccessForViewCardsWithBranches = useMemo(
        () =>
            roles &&
            (roles.includes(CUSTOMER_ROLES.CLERK) ||
                roles.includes(CUSTOMER_ROLES.CLERK_PLUS) ||
                roles.includes(CUSTOMER_ROLES.ADMINISTRATOR) ||
                roles.includes(CUSTOMER_ROLES.ADMINISTRATOR_PLUS)) &&
            userId !== id &&
            !roles.includes(CUSTOMER_ROLES.ACCOUNT_OWNER) &&
            ability.can(
                AbilityActions.edit,
                AbilitySubjects.userManagementCardsWithBranches
            ),
        [roles, userId, id, ability]
    );

    const makeRequestForUserData = (): void => {
        setGetUserDataRequestLoading(true);
        setCreateUserMode(AddUserMode.none);
        setMenuListDisabled(true);
        httpClient
            .get(`users/${activeUser}`)
            .then((data: UserGetResponseBody) => {
                const { internalClientCards, ...rest } = getUserFormatter(data);
                setInternalClientCards(internalClientCards);
                setHasTwoFactorAuth(data.hasTwoFactorAuth);
                reset(rest);
            })
            .finally(() => {
                setGetUserDataRequestLoading(false);
                setCreateUserMode(AddUserMode.none);
                setMenuListDisabled(false);
            });
    };

    const makeRequestForClientConnections = (): Promise<IClientConnections[] | void> => {
        setMenuListDisabled(true);
        const queryParams: {
            id: string;
            email: string;
            userGroup: string;
            roles: string[];
            customerAdvisorNumber?: string;
        } = {
            id,
            email,
            userGroup,
            roles,
        };
        if (customerAdvisorNumber) {
            queryParams.customerAdvisorNumber = customerAdvisorNumber;
        }
        setAllCustomerConnectionsAssined(false);
        return httpClient
            .get<IClientConnections[]>(
                `users/client-connections?${qs.stringify(queryParams)}`
            )
            .catch((err) => {
                setMenuListDisabled(false);
                if (
                    err?.error?.response?.data?.errors?.userCustomerConnections?.includes(
                        'All customer connections you are responsible for have already been assigned to an account owner.'
                    )
                ) {
                    setAllCustomerConnectionsAssined(true);
                }
                err?.error?.response?.data?.errors?.Email?.forEach((error) => {
                    enqueueSnackbar(t(`user-management:umSection.${error}`), {
                        variant: 'error',
                    });
                });
            });
    };

    const handleAddClientConnections = (): void => {
        if (forcedClientConnections) {
            return;
        }

        makeRequestForClientConnections().then((data) => {
            setMenuListDisabled(false);
            if (Array.isArray(data)) {
                const newClientConnections = data?.map((item) => {
                    return {
                        title: item.customerConnection,
                        value: item.id,
                        checked: Boolean(
                            chosenClientConnections?.find(
                                (chosenClientConnection) =>
                                    chosenClientConnection.id === item.id
                            )
                        ),
                        ...item,
                        data: undefined,
                    };
                });
                setClientConnections(newClientConnections);
            }
        });
    };

    useEffect(() => {
        if (forcedClientConnections) {
            console.log('update cc');
            setClientConnections(forcedClientConnections);
        }
    }, [forcedClientConnections]);

    useEffect(() => {
        if (
            Array.isArray(initialStateOfCardsWithBranches) &&
            initialStateOfCardsWithBranches?.length > 0 &&
            Array.isArray(chosenClientConnections)
        ) {
            const newCardsWithBranches = [];
            chosenClientConnections.forEach((card) => {
                const {
                    filteredClientConnections,
                    clients,
                    clientConnectionValue,
                    divisions,
                } = userEditFormBuildDataForCardWithBranches(
                    initialStateOfCardsWithBranches,
                    newCardsWithBranches,
                    card.id,
                    card
                );
                if (newCardsWithBranches.length > 0) {
                    newCardsWithBranches[
                        newCardsWithBranches.length - 1
                    ].clientConnectionDisabled = true;
                }
                newCardsWithBranches.push({
                    clientConnectionValue,
                    id: String((newCardsWithBranches?.length || 0) + 1),
                    clientConnections: filteredClientConnections,
                    clientConnectionDisabled: false,
                    clients: clients,
                    divisions: divisions,
                });
            });
            setCardsWithBranches(newCardsWithBranches);
        }
    }, [initialStateOfCardsWithBranches]);

    useEffect(() => {
        if (!activeUser) {
            setEditMode(true);
            reset({
                name: '',
                surname: '',
                email: '',
                phoneNumber: '',
                userGroup: '',
                customerAdvisorNumber: '',
                active: true,
            });
            trigger();
        } else {
            closeEditMode();
        }
    }, [activeUser]);

    useEffect(() => {
        if (!isEmailFieldFocused && !isCustomerAdvisorNumberFocused) {
            handleClearCards();
            if (
                roles &&
                roles.some(
                    (role) =>
                        role === INTERNAL_ROLES.CUSTOMER_ADVISER ||
                        role === INTERNAL_ROLES.CA_BROKER
                )
            ) {
                handleAddInternalCard();
            } else if (isAccessForViewCardsWithBranches) {
                handleSetInitialCardsWithBranches();
            } else if (
                (roles && roles.includes(CUSTOMER_ROLES.ACCOUNT_OWNER)) ||
                ((roles.includes(CUSTOMER_ROLES.ADMINISTRATOR) ||
                    roles.includes(CUSTOMER_ROLES.ADMINISTRATOR_PLUS)) &&
                    userId === id)
            ) {
                handleAddClientConnections();
            } else {
                handleClearCards();
            }
        }
    }, [roles, isEmailFieldFocused, isCustomerAdvisorNumberFocused]);

    const handleSetInitialCardsWithBranches = (): void => {
        makeRequestForClientConnections().then((data) => {
            setMenuListDisabled(false);
            setInitialStateOfCardsWithBranches(data);
        });
    };

    const handleAddCardWithBranches = (): void => {
        const { filteredClientConnections, clients, clientConnectionValue, divisions } =
            userEditFormBuildDataForCardWithBranches(
                initialStateOfCardsWithBranches,
                cardsWithBranches
            );
        setCardsWithBranches([
            ...(cardsWithBranches
                ? cardsWithBranches.map((item: WithBranchFormCard) => ({
                      ...item,
                      clientConnectionDisabled: true,
                  }))
                : []),
            {
                clientConnectionValue,
                id: String((cardsWithBranches?.length || 0) + 1),
                clientConnections: filteredClientConnections,
                clientConnectionDisabled: false,
                clients: clients,
                divisions: divisions,
            },
        ]);
    };

    const handleAddInternalCard = (): void => {
        makeRequestForClientConnections().then((data: UserClientsServerData[] | void) => {
            if (data && forcedClientConnections) {
                data = data.filter(
                    (item) =>
                        !forcedClientConnections.some(
                            (cc) => cc.value === item.customerConnection
                        )
                );
            }
            setMenuListDisabled(false);
            if (!data || data.length === 0) {
                setInternalClientCards([]);
                return;
            }
            const clientConnections = [];
            const clients = [];
            data.forEach((item: UserClientsServerData) => {
                clientConnections.push({
                    title: item.customerConnection,
                    value: item.customerConnection,
                    checked: true,
                    data: item,
                });
                item.customers.forEach((customer) => {
                    clients.push({
                        title: `${customer.customer} 
                        ${customer.customerName2 ? ' - ' + customer.customerName2 : ''} ${
                            customer.customerName3 ? ' - ' + customer.customerName3 : ''
                        }`,
                        value: customer.id,
                        checked: true,
                        data: customer,
                    });
                });
            });
            setInternalClientCards([
                {
                    id: '1',
                    clientConnections: clientConnections,
                    clients: clients,
                },
            ]);
        });
    };
    const onSubmit = async (formData: UserEditingFormData): Promise<void> => {
        const requestType = activeUser
            ? UserEditFormRequestTypes.EDIT
            : UserEditFormRequestTypes.CREATE;
        debugger;

        const dataForRequest = userEditFormPreparingDataForRequest(
            formData,
            clientConnections,
            requestType,
            activeUser,
            cardsWithBranches,
            initialStateOfCardsWithBranches
        );
        setMutateLoading(true);
        try {
            setMenuListDisabled(true);
            if (!activeUser) {
                const { id } = await httpClient.post<UserEditFormRequestBody>(
                    dataForRequest.url,
                    dataForRequest.data
                );
                closeEditMode();
                enqueueSnackbar(
                    t('user-management:umSection.userIsCreatedSuccessfully'),
                    {
                        variant: 'success',
                    }
                );
                setActiveUser(id);
            } else {
                await httpClient.put(dataForRequest.url, dataForRequest.data);
                if (userId === id) {
                    getUserData();
                }
                closeEditMode();
                enqueueSnackbar(t('user-management:umSection.userIsEditedSuccessfully'), {
                    variant: 'success',
                });
            }
            makeRequestForUsersList();
        } catch (err) {
            err?.error?.response?.data?.errors?.email.forEach((error) => {
                enqueueSnackbar(t(`user-management:umSection.${error}`), {
                    variant: 'error',
                });
            });
            if (
                !err?.error?.response?.data?.errors ||
                err?.error?.response?.data?.errors.length === 0
            ) {
                enqueueSnackbar(t('errors:unknownError'), {
                    variant: 'error',
                });
            }
        } finally {
            setMutateLoading(false);
            setMenuListDisabled(false);
        }
    };

    const openEditMode = (): void => {
        setEditMode(true);
        setCreateUserMode(AddUserMode.none);
        setNewUser(false);
    };

    const clearTwoFactor = async () => {
        try {
            await httpClient.post('two-factor/admin-delete', {
                userId: activeUser,
            });

            enqueueSnackbar(t(`user-management:umSection.clearTwoFactorSuccess`), {
                variant: 'success',
            });

            await makeRequestForUserData();
        } catch {
            enqueueSnackbar(t(`user-management:umSection.clearTwoFactorFailed`), {
                variant: 'error',
            });
        }
    };

    const closeEditMode = (): void => {
        setEditMode(false);
        setCreateUserMode(AddUserMode.newUser);
        setNewUser(false);
        if (activeUser) {
            makeRequestForUserData();
            setActiveUser(activeUser);
        }
    };

    const handleChangeRoles = (e: BaseSyntheticEvent): void => {
        setValue(
            'roles',
            e.target.value.map((item: string) => ({ name: item }))
        );
        void trigger();
    };

    const handleChangeValue = (e: BaseSyntheticEvent): void => {
        if (e.target.name === 'userGroup') {
            setValue('roles', []);
        }
        setValue(e.target.name, e.target.value);
        void trigger();
    };

    const handleChangeActive = (): void => {
        setValue('active', !active);
    };

    const handleClearCards = (): void => {
        if (forcedClientConnections) {
            return;
        }

        setInternalClientCards([]);
        setClientConnections([]);
        setCardsWithBranches(null);
        void trigger();
    };

    const handleChangeCardWithBranchValues = (
        id: string,
        newCard: WithBranchFormCard
    ): void => {
        const newClientCards = [...(cardsWithBranches ? cardsWithBranches : [])];
        const cardIndex = newClientCards.findIndex((client) => client.id === id);
        newClientCards[cardIndex] = newCard;
        setCardsWithBranches(newClientCards);
    };

    const userRolesGroupSelectionOptions = useMemo(
        () =>
            generateUsersEditFormGroupSelectOptions(
                t,
                ability,
                accessToEditForm,
                createUserMode
            ),
        [t, ability, accessToEditForm]
    );

    const userRolesSelectionOptions = useMemo(
        () =>
            generateUserEditFormRoleSelectOptions(
                t,
                userGroup,
                userId === id,
                roleFields,
                ability,
                createUserMode
            ),
        [t, userGroup, userId, id, roleFields, ability]
    );

    useEffect(() => {
        if (createUserMode === AddUserMode.changeAccountOwner) {
            setValue('roles', [{ name: CUSTOMER_ROLES.ACCOUNT_OWNER }]);
            setValue('userGroup', USER_GROUPS.CUSTOMER);
        }
    }, [createUserMode]);

    const isCardsStateIsNotValid = useMemo(() => {
        const internalClientCardValid = validateInternalClientCard(
            roles,
            internalClientCards
        );
        const clientConnectionsCardValid = validateClientConnectionsCard(
            roles,
            ability,
            clientConnections
        );
        const cardsWithBranchesValid = validateCardsWithBranches(
            roles,
            ability,
            userId,
            id,
            cardsWithBranches
        );

        return (
            !internalClientCardValid ||
            !clientConnectionsCardValid ||
            !cardsWithBranchesValid
        );
    }, [
        roles,
        internalClientCards,
        clientConnections,
        ability,
        id,
        userId,
        cardsWithBranches,
    ]);

    const toggleAccountOwnerActiveStatus = async (): Promise<void> => {
        await httpClient.post(
            `users/${activeUser}/customer/update-account-owner-active-status`,
            {
                active: !active,
            }
        );
        enqueueSnackbar(t('user-management:umSection.accountOwnerStatusUpdated'), {
            variant: 'success',
        });
        await makeRequestForUsersList();
        await makeRequestForUserData();
        setIsSetAccountOwnerActiveStatusOpen(false);
    };

    const deleteAccountOwner = async (): Promise<void> => {
        await httpClient.delete(`users/${activeUser}/delete-account-owner`);
        enqueueSnackbar(t('user-management:umSection.accountOwnerDeleted'), {
            variant: 'success',
        });
        setActiveUser('');
        await makeRequestForUsersList();
        setIsDeleteAccountOwnerOpen(false);
    };

    if (isGetUserDataRequestLoading) {
        return <ThemeLoader />;
    }

    const isActiveUserAccountOwner = roles.includes(CUSTOMER_ROLES.ACCOUNT_OWNER);

    console.log(clientConnections);

    return (
        <>
            <Confirmation
                onConfirm={deleteAccountOwner}
                isOpen={isDeleteAccountOwnerOpen}
                onClose={() => setIsDeleteAccountOwnerOpen(false)}
                text={t('user-management:umSection.deleteUserConfirmation')}
            />
            <Confirmation
                onConfirm={toggleAccountOwnerActiveStatus}
                isOpen={isSetAccountOwnerActiveStatusOpen}
                onClose={() => setIsSetAccountOwnerActiveStatusOpen(false)}
                text={t(
                    active
                        ? 'user-management:umSection.deactivateAccountOwnerConfirmation'
                        : 'user-management:umSection.activateAccountOwnerConfirmation'
                )}
            />
            <Grid container component='form' onSubmit={handleSubmit(onSubmit)}>
                <Grid container item className={classes.header}>
                    <Typography variant='h5' className={classes.formTitle}>
                        {activeUser
                            ? t('user-management:umSection.editUser')
                            : t('user-management:umSection.newUser')}
                    </Typography>
                    {activeUser && editMode && id !== userId && (
                        <UserEditFormApproveDeletingModalWindow
                            activeUser={activeUser}
                            makeRequestForUsersList={makeRequestForUsersList}
                            handleAddNewUser={handleAddNewUser}
                        />
                    )}
                    <Grid item container className={classes.formControlButtons}>
                        <UserActivation
                            id={id}
                            activated={activated}
                            roles={[INTERNAL_ROLES.ADMIN_TECHNICAL]}
                            makeRequestForUsersList={makeRequestForUsersList}
                            setActiveUser={setActiveUser}
                        />
                        {!editMode && accessToEditForm && (
                            <>
                                {ability.can(
                                    AbilityActions.edit,
                                    AbilitySubjects.manageAccountOwners
                                ) &&
                                    isActiveUserAccountOwner && (
                                        <>
                                            <Button
                                                className={classes.editButton}
                                                startIcon={<DeleteIcon />}
                                                onClick={() =>
                                                    setIsDeleteAccountOwnerOpen(true)
                                                }
                                            >
                                                {t(
                                                    'user-management:umSection.deleteAccountOwner'
                                                )}
                                            </Button>

                                            <Button
                                                className={classes.editButton}
                                                startIcon={<DeleteIcon />}
                                                onClick={() =>
                                                    setIsSetAccountOwnerActiveStatusOpen(
                                                        true
                                                    )
                                                }
                                            >
                                                {t(
                                                    active
                                                        ? 'user-management:umSection.deactivateAccountOwner'
                                                        : 'user-management:umSection.activateAccountOwner'
                                                )}
                                            </Button>

                                            <Button
                                                className={classes.editButton}
                                                startIcon={<EditIcon />}
                                                onClick={() =>
                                                    handleChangeAccountOwner(
                                                        clientConnections.filter(
                                                            (cc) => cc.checked
                                                        )
                                                    )
                                                }
                                            >
                                                {t(
                                                    'user-management:umSection.changeAccountOwner'
                                                )}
                                            </Button>
                                        </>
                                    )}

                                {ability.can(
                                    AbilityActions.delete,
                                    AbilitySubjects.manageTwoFactor
                                ) &&
                                    hasTwoFactorAuth && (
                                        <Button
                                            onClick={clearTwoFactor}
                                            className={classes.editButton}
                                            startIcon={<DeleteIcon />}
                                        >
                                            {t('user-management:clearTwoFactor')}
                                        </Button>
                                    )}

                                <Button
                                    onClick={openEditMode}
                                    className={classes.editButton}
                                    startIcon={<EditIcon />}
                                >
                                    {t('edit')}
                                </Button>
                            </>
                        )}

                        {editMode && (
                            <>
                                <Button
                                    disableElevation
                                    disabled={
                                        !isValid ||
                                        isMutateLoading ||
                                        isCardsStateIsNotValid
                                    }
                                    startIcon={
                                        <ThemeCircularProgress
                                            isLoading={isMutateLoading}
                                        />
                                    }
                                    type='submit'
                                    variant='contained'
                                    className={classes.containedButton}
                                >
                                    {t('save')}
                                </Button>
                                <Button
                                    disableElevation
                                    variant='outlined'
                                    onClick={closeEditMode}
                                    className={classes.outlinedButton}
                                >
                                    {t('cancel')}
                                </Button>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid container className={classes.staticFieldsContainer}>
                    <Grid item md={3}>
                        <FormControl
                            fullWidth
                            error={Boolean(errors?.name)}
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                label={t('user-management:umSection.form.name')}
                                labelPlacement='top'
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={!editMode}
                                        classes={inputClasses}
                                        value={name}
                                        onChange={handleChangeValue}
                                        name='name'
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                            error={Boolean(errors?.surname)}
                        >
                            <FormControlLabel
                                label={t('user-management:umSection.form.surname')}
                                labelPlacement='top'
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={!editMode}
                                        classes={inputClasses}
                                        value={surname}
                                        onChange={handleChangeValue}
                                    />
                                }
                                name='surname'
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={3}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                label={t('user-management:umSection.form.phoneNumber')}
                                labelPlacement='top'
                                classes={formControlLabelClasses}
                                control={
                                    <ThemePhoneNumberInput
                                        disabled={!editMode}
                                        value={phoneNumber}
                                        onChange={handleChangeValue}
                                        name='phoneNumber'
                                    />
                                }
                                name='surname'
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={3}>
                        <FormControl
                            fullWidth
                            error={Boolean(errors?.email)}
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                label={t('user-management:umSection.form.email')}
                                labelPlacement='top'
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={!editMode}
                                        classes={inputClasses}
                                        value={email}
                                        onChange={handleChangeValue}
                                        name='email'
                                        onFocus={() => setEmailFieldFocus(true)}
                                        onBlur={() => setEmailFieldFocus(false)}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={3}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                            style={{ width: 'fit-content' }}
                        >
                            <FormControlLabel
                                label={t('user-management:umSection.form.active')}
                                labelPlacement='top'
                                classes={formControlLabelClasses}
                                control={
                                    <div className={classes.switchContainer}>
                                        <ThemeSwitcher
                                            disabled={userId !== id ? !editMode : true}
                                            checked={!active}
                                            onChange={handleChangeActive}
                                            leftLabel={t('yes')}
                                            rightLabel={t('no')}
                                        />
                                    </div>
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid md={3} />

                    <Grid item md={3}>
                        <FormControl
                            fullWidth
                            error={Boolean(errors?.userGroup)}
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                label={t('user-management:umSection.form.userGroup')}
                                labelPlacement='top'
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeSelectInput
                                        editMode={!activeUser ? editMode : false}
                                        name='userGroup'
                                        value={userGroup}
                                        items={userRolesGroupSelectionOptions}
                                        onChange={handleChangeValue}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3}>
                        {userEditFormRenderRoleFields({
                            t,
                            ability,
                            accessToEditForm,
                            inputClasses,
                            formControlClasses,
                            formControlLabelClasses,
                            editMode,
                            handleChangeRoles,
                            errors,
                            value: roles,
                            items: userRolesSelectionOptions,
                        })}
                    </Grid>
                    {isAccessForViewCardsWithBranches && (
                        <Grid
                            item
                            container
                            md={5}
                            alignItems='flex-end'
                            style={{ marginBottom: 5 }}
                        >
                            <UserAutomaticCustomersAssigning
                                addNewCustomersAutomatically={
                                    addNewCustomersAutomatically
                                }
                                setValue={setValue}
                                editMode={editMode}
                            />
                        </Grid>
                    )}
                    {appInstance === AppInstances.AON_KOMPOSIT &&
                        roles.includes(INTERNAL_ROLES.CUSTOMER_ADVISER) && (
                            <Grid item md={3}>
                                <ThemeOutlineInput
                                    disabled={!editMode}
                                    name='customerAdvisorNumber'
                                    label={t(
                                        'user-management:umSection.form.customerAdvisorNumber'
                                    )}
                                    value={customerAdvisorNumber}
                                    error={Boolean(errors?.customerAdvisorNumber)}
                                    handleChangeValue={handleChangeValue}
                                    formControlClasses={formControlClasses}
                                    formControlLabelClasses={formControlLabelClasses}
                                    inputClasses={inputClasses}
                                    onFocus={() => setCustomerAdvisorFocused(true)}
                                    onBlur={() => setCustomerAdvisorFocused(false)}
                                />
                            </Grid>
                        )}
                </Grid>
                {((roles && roles.includes(CUSTOMER_ROLES.ACCOUNT_OWNER)) ||
                    ((roles.includes(CUSTOMER_ROLES.ADMINISTRATOR) ||
                        roles.includes(CUSTOMER_ROLES.ADMINISTRATOR_PLUS)) &&
                        userId === id)) && (
                    <Grid className={classes.dynamicFieldsContainer}>
                        <UserEditFormClientConnectionCard
                            isAllCustomerConnectionsAreAssigned={
                                isAllCustomerConnectionsAreAssigned
                            }
                            clientConnections={clientConnections}
                            setClientConnections={setClientConnections}
                            editMode={
                                ability.can(
                                    AbilityActions.edit,
                                    AbilitySubjects.userManagementClientConnectionCard
                                )
                                    ? editMode && userId !== id
                                    : false
                            }
                        />
                    </Grid>
                )}
                {roles &&
                    roles.some(
                        (role) =>
                            role === INTERNAL_ROLES.CUSTOMER_ADVISER ||
                            role === INTERNAL_ROLES.CA_BROKER
                    ) && (
                        <Grid className={classes.dynamicFieldsContainer}>
                            {internalClientCards?.map(
                                (internalClientCard: InternalClientCard) => (
                                    <UserEditingFormInternalCard
                                        readonly
                                        key={internalClientCard.id}
                                        internalClientCard={internalClientCard}
                                    />
                                )
                            )}
                        </Grid>
                    )}
                {isAccessForViewCardsWithBranches && (
                    <>
                        {cardsWithBranches?.map((clientCard: WithBranchFormCard) => (
                            <Grid
                                key={clientCard.id}
                                className={classes.dynamicFieldsContainer}
                            >
                                <UserEditingFormWithBranchCard
                                    editMode={editMode}
                                    clientCard={clientCard}
                                    onChange={handleChangeCardWithBranchValues}
                                    initialStateOfCardsWithBranches={
                                        initialStateOfCardsWithBranches
                                    }
                                    cardsWithBranches={cardsWithBranches}
                                    setValue={setCardsWithBranches}
                                />
                            </Grid>
                        ))}
                        {editMode &&
                            Array.isArray(initialStateOfCardsWithBranches) &&
                            cardsWithBranches?.length !==
                                initialStateOfCardsWithBranches.length && (
                                <Button
                                    startIcon={<AddIcon />}
                                    variant='outlined'
                                    onClick={handleAddCardWithBranches}
                                    className={classes.addCustomerButton}
                                >
                                    {t('user-management:umSection.form.addCustomer')}
                                </Button>
                            )}
                    </>
                )}
            </Grid>
        </>
    );
};
