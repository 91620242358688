import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Grid, Paper } from '@material-ui/core';

import { ThemeToggleButtons } from 'src/theming';
import { AppInstances } from 'src/shared/constants';

import { ContractDetailsViewKFZWDetails } from './contract-details-view-kfzw/contract-details-view-kfzw-details';
import { ContractDetailsViewBhv } from './contract-details-view-bhv/contract-details-view-bhv';
import { ContractDetailsViewGbw } from './contract-details-view-gbw/contract-details-view-gbw';
import { ContractDetailsViewPhv } from './contract-details-view-phv/contract-details-view-phv';
import { ContractDetailsViewGesch } from './contract-details-view-gesch/contract-details-view-gesch';
import { ContractDetailsViewConstruction } from './contract-details-view-construction/contract-details-view-construction';
import { ContractDetailsViewIS } from './contract-details-view-is/contract-details-view-is';
import { ContractDetailsViewElectro } from './contract-details-view-electro/contract-details-view-electro';
import { ContractDetailsViewMaschine } from './contract-details-view-maschine/contract-details-view-maschine';
import { ContractDetailsViewGlasDetails } from './contract-details-view-glas/contract-details-view-glas-details';
import { ContractDetailsViewDREISE } from './contract-details-view-dreise/contract-details-view-dreise';
import { ContractDetailsViewLvrDetails } from './contract-details-view-lvr/contract-details-view-lvr';

import { usePaperStyle, useStyle } from './contracts-details-view-styles';

import { Divisions } from 'src/shared/constants';
import { ContractDetailsViewTranspDetails } from './contract-details-view-transp/contract-details-view-transp';
import { ContractDetailsViewMontageDetails } from './contract-details-view-montage/contract-details-view-montage';
import { ContractDetailsViewPrvDetails } from './contract-details-view-prv/contract-details-view-prv';
import { ContractDetailsViewHr } from './contract-details-view-hr/contract-details-view-hr';
import { ContractDetailsViewBu } from './contract-details-view-bu/contract-details-view-bu';

import { ContractDetailsViewDamages } from './contract-details-view-damages';

import { appInstance } from 'src/environment';

const isAonKompositInstance = appInstance === AppInstances.AON_KOMPOSIT;

export enum SECTIONS {
    CONTRACTS = 'contracts',
    DAMAGES = 'damages',
}

interface ContractDetailsViewDetailsProps {
    division: string;
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    documentFormats: string[];
    contractNumber: string;
}

export const ContractDetailsViewDetails = (
    props: ContractDetailsViewDetailsProps
): JSX.Element => {
    const classes = useStyle();
    const paperClasses = usePaperStyle();
    const { t } = useTranslation(['contract-details-view']);

    const location = useLocation<{ preselectedTab: SECTIONS }>();

    const preselectedTab = location?.state?.preselectedTab;

    const {
        division,
        handleOpenContractEdit,
        hideThemeNestedMenu,
        documentFormats,
        contractNumber,
    } = props;

    const [activeSection, setActiveSection] = useState(
        !isAonKompositInstance
            ? preselectedTab || SECTIONS.CONTRACTS.toString()
            : SECTIONS.DAMAGES
    );

    return (
        <Paper classes={paperClasses}>
            <Grid container className={classes.contractDetailsButtonsContainer}>
                <ThemeToggleButtons
                    injectedClassName={classes.buttonGroups}
                    value={activeSection.toString()}
                    handleChange={(_: SyntheticEvent, newSection: string) => {
                        if (newSection) {
                            setActiveSection(newSection);
                        }
                    }}
                    buttonsList={[
                        ...(!isAonKompositInstance
                            ? [
                                  {
                                      title: t(
                                          'contract-details-view:contractDetails.contractDetails'
                                      ),
                                      value: SECTIONS.CONTRACTS,
                                  },
                              ]
                            : []),
                        {
                            title: t('contract-details-view:contractDetails.damages'),
                            value: SECTIONS.DAMAGES,
                        },
                    ]}
                />
            </Grid>
            <div className={classes.chosenSection}>
                {activeSection === SECTIONS.DAMAGES && <ContractDetailsViewDamages />}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.KFZW && (
                    <ContractDetailsViewKFZWDetails
                        documentFormats={documentFormats}
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.GLAS && (
                    <ContractDetailsViewGlasDetails
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.BHV && (
                    <ContractDetailsViewBhv
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.GBW && (
                    <ContractDetailsViewGbw
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.PHV && (
                    <ContractDetailsViewPhv
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
                {activeSection === SECTIONS.CONTRACTS &&
                    division === Divisions.BAULEIST && (
                        <ContractDetailsViewConstruction
                            hideThemeNestedMenu={hideThemeNestedMenu}
                            handleOpenContractEdit={handleOpenContractEdit}
                            contractNumber={contractNumber}
                        />
                    )}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.GESCH && (
                    <ContractDetailsViewGesch
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.IS && (
                    <ContractDetailsViewIS
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
                {activeSection === SECTIONS.CONTRACTS &&
                    division === Divisions.ELECTRO && (
                        <ContractDetailsViewElectro
                            hideThemeNestedMenu={hideThemeNestedMenu}
                            handleOpenContractEdit={handleOpenContractEdit}
                            contractNumber={contractNumber}
                        />
                    )}
                {activeSection === SECTIONS.CONTRACTS &&
                    division === Divisions.MACHINE && (
                        <ContractDetailsViewMaschine
                            hideThemeNestedMenu={hideThemeNestedMenu}
                            handleOpenContractEdit={handleOpenContractEdit}
                            contractNumber={contractNumber}
                        />
                    )}
                {activeSection === SECTIONS.CONTRACTS &&
                    division === Divisions.DREISE && (
                        <ContractDetailsViewDREISE
                            hideThemeNestedMenu={hideThemeNestedMenu}
                            handleOpenContractEdit={handleOpenContractEdit}
                            contractNumber={contractNumber}
                        />
                    )}
                {activeSection === SECTIONS.CONTRACTS &&
                    division === Divisions.TRANSP && (
                        <ContractDetailsViewTranspDetails
                            hideThemeNestedMenu={hideThemeNestedMenu}
                            handleOpenContractEdit={handleOpenContractEdit}
                            contractNumber={contractNumber}
                        />
                    )}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.LVR && (
                    <ContractDetailsViewLvrDetails
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
                {activeSection === SECTIONS.CONTRACTS &&
                    division === Divisions.MONTAGE && (
                        <ContractDetailsViewMontageDetails
                            hideThemeNestedMenu={hideThemeNestedMenu}
                            handleOpenContractEdit={handleOpenContractEdit}
                            contractNumber={contractNumber}
                        />
                    )}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.PRV && (
                    <ContractDetailsViewPrvDetails
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.HR && (
                    <ContractDetailsViewHr
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
                {activeSection === SECTIONS.CONTRACTS && division === Divisions.BU && (
                    <ContractDetailsViewBu
                        hideThemeNestedMenu={hideThemeNestedMenu}
                        handleOpenContractEdit={handleOpenContractEdit}
                        contractNumber={contractNumber}
                    />
                )}
            </div>
        </Paper>
    );
};
