import React, {
    useState,
    ChangeEvent,
    useEffect,
    useRef,
    useMemo,
    BaseSyntheticEvent,
    ReactElement,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import qs from 'query-string';

import {
    FormControl,
    Grid,
    InputAdornment,
    OutlinedInput,
    Typography,
    Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import {
    ThemeListMenu,
    ThemeLoader,
    ThemeMessageComponent,
    MessageTypes,
    SelectItem,
    CheckboxItem,
} from 'src/theming';
import { UserManagementNavigationPanel } from 'src/user-management/user-management-navigation-panel/user-management-navigation-panel';

import { UserEditingForm } from '..';

import { useDebounce } from 'src/lib/custom-hooks/use-debounce';

import {
    useStyle,
    useInputStyles,
    useFormControlStyles,
} from './user-management-section-styles';

import { userManagementSectionUserListFormatter } from './user-management-section-user-list-formatter';
import { userManagementSectionGetUserIcon } from './user-management-section-get-user-icon';

import {
    UserManagementSectionResponseBody,
    UserManagementSectionResponseBodyResult,
} from './user-management-section-response-body';

type UserListFormData = {
    searchValue: string;
    page: number;
    rowsPerPage: number;
};

export enum AddUserMode {
    none,
    newUser,
    changeAccountOwner,
}

export const UserManagementSection = (): JSX.Element => {
    const classes = useStyle();
    const formControlClasses = useFormControlStyles();
    const inputClasses = useInputStyles();
    const userListContainerRef = useRef(null);

    const { t } = useTranslation(['common', 'user-management']);

    const httpClient = useHttpClient();

    const [userListMenuMaxHeight, setUserListMenuMaxHeight] = useState(null);
    const [activeUser, setActiveUser] = useState<string>('');
    const [createUserMode, setCreateUserMode] = useState<AddUserMode>(AddUserMode.none);
    const [isMounted, setMounted] = useState<boolean>(false);
    const [isUsersListLoading, setUsersListLoading] = useState<boolean>(false);
    const [usersList, setUsersList] = useState(null);
    const [isMenuListDisabled, setMenuListDisabled] = useState<boolean>(false);
    const [isNewUser, setNewUser] = useState<boolean>(false);
    const [forcedClientConnections, setForcedClientConnections] =
        useState<CheckboxItem[]>();

    const { watch, setValue } = useForm<UserListFormData>({
        mode: 'onChange',
        defaultValues: { searchValue: '', page: 0, rowsPerPage: 10 },
    });

    const [searchValue, page, rowsPerPage] = watch([
        'searchValue',
        'page',
        'rowsPerPage',
    ]);

    const debouncedSearchValue = useDebounce(searchValue, 500);

    const makeRequestForUsersList = (): Promise<UserManagementSectionResponseBody> => {
        const skip = (page + 1) * rowsPerPage - rowsPerPage;
        const take = rowsPerPage;
        const queryParams: { take: number; skip: number; searchTerm?: string } = {
            take,
            skip,
        };
        if (searchValue) {
            queryParams.searchTerm = searchValue;
        }
        setUsersListLoading(true);
        return httpClient
            .get(`users?${qs.stringify(queryParams)}`)
            .then((data: UserManagementSectionResponseBody) => {
                if (data) {
                    setUsersList(userManagementSectionUserListFormatter(data));
                }
                if (!activeUser && !isNewUser) {
                    setActiveUser(data?.result[0]?.id);
                    if (data?.result?.length > 0) {
                        setNewUser(false);
                    }
                }
                return data;
            })
            .finally(() => {
                setUsersListLoading(false);
            });
    };

    useEffect(() => setMounted(true), []);

    useEffect(() => {
        if (isMounted) {
            makeRequestForUsersList();
        }
    }, [debouncedSearchValue, isMounted, rowsPerPage, page, isNewUser]);

    useEffect(() => {
        if (userListContainerRef.current) {
            setUserListMenuMaxHeight(userListContainerRef.current?.offsetHeight);
        }
    }, [userListContainerRef]);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue('searchValue', e.target.value);
        setActiveUser('');
    };

    const handleChangeRowsPerPage = (
        _: BaseSyntheticEvent,
        value: ReactElement
    ): void => {
        setValue('rowsPerPage', value.props.value);
        setActiveUser('');
    };

    const handleChangePage = (_: BaseSyntheticEvent, value: number): void => {
        setValue('page', value);
        setActiveUser('');
    };

    const handleAddNewUser = (mode: AddUserMode): void => {
        setActiveUser('');
        setCreateUserMode(mode);
        setNewUser(true);
        setValue('searchValue', '');
    };

    const usersListWithIcons = useMemo(
        () =>
            usersList?.result?.map((user: UserManagementSectionResponseBodyResult) => ({
                ...user,
                icon: userManagementSectionGetUserIcon(
                    user?.roles,
                    activeUser === user.id
                ),
            })),
        [usersList, activeUser]
    );

    console.log(forcedClientConnections);

    return (
        <Grid container className={classes.userManagementContainer}>
            <Grid container item className={classes.toolBar}>
                <UserManagementNavigationPanel activeMenu='user-management' />
                <div style={{ flexGrow: 1 }} />
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <OutlinedInput
                        placeholder={t('search')}
                        startAdornment={
                            <InputAdornment position='start'>
                                <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                        }
                        classes={inputClasses}
                        value={searchValue}
                        onChange={handleSearchChange}
                    />
                </FormControl>
            </Grid>
            <Grid container className={classes.header}>
                <Typography variant='h5' className={classes.title}>
                    {t('user-management:umSection.title')}
                </Typography>
                {createUserMode === AddUserMode.none && (
                    <Button
                        className={classes.addUserButton}
                        onClick={() => handleAddNewUser(AddUserMode.newUser)}
                    >
                        {t('user-management:umSection.addUser')}
                    </Button>
                )}
            </Grid>
            <Grid container className={classes.mainContentMenu}>
                <Grid
                    item
                    ref={userListContainerRef}
                    className={classes.usersListContainer}
                >
                    {isUsersListLoading && <ThemeLoader />}
                    {!isUsersListLoading && (
                        <ThemeListMenu
                            isMenuListDisabled={isMenuListDisabled}
                            noDataMessage={t('user-management:umSection.noUsers')}
                            activeItem={activeUser}
                            maxHeight={userListMenuMaxHeight}
                            list={usersListWithIcons || []}
                            menuTitle={t('user-management:umSection.userList')}
                            setActiveItem={setActiveUser}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            count={usersList?.count || 0}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={
                                handleChangeRowsPerPage as React.ChangeEventHandler<
                                    HTMLTextAreaElement | HTMLInputElement
                                >
                            }
                        />
                    )}
                </Grid>
                <Grid item className={classes.userSettingsContainer}>
                    {!isUsersListLoading &&
                        (!Array.isArray(usersListWithIcons) ||
                            usersListWithIcons.length === 0) &&
                        !isNewUser && (
                            <ThemeMessageComponent
                                message={t('user-management:umSection.noUserData')}
                                type={MessageTypes.INFO}
                            />
                        )}
                    {((activeUser && usersListWithIcons?.length > 0) ||
                        createUserMode !== AddUserMode.none) && (
                        <UserEditingForm
                            createUserMode={createUserMode}
                            activeUser={activeUser}
                            setNewUser={setNewUser}
                            setCreateUserMode={setCreateUserMode}
                            makeRequestForUsersList={makeRequestForUsersList}
                            handleAddNewUser={() => handleAddNewUser(AddUserMode.newUser)}
                            handleChangeAccountOwner={(cc) => {
                                setForcedClientConnections(cc);
                                handleAddNewUser(AddUserMode.changeAccountOwner);
                            }}
                            forcedClientConnections={forcedClientConnections}
                            setMenuListDisabled={setMenuListDisabled}
                            setActiveUser={(activeUser) => {
                                setForcedClientConnections(undefined);
                                setActiveUser(activeUser);
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
