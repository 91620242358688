import { CheckboxItem } from 'src/theming';
import {
    UserClientsServerData,
    UserEditingFormData,
    UserGetResponseBody,
    InternalClientCard,
} from './user-edit-form-types';

export const getUserFormatter = (
    data: UserGetResponseBody
): UserEditingFormData & {
    internalClientCards: InternalClientCard[];
    chosenClientConnections: UserClientsServerData[];
} => {
    const clientConnections: CheckboxItem[] = [];
    const clients: CheckboxItem[] = [];
    data.customerConnections?.forEach((item: UserClientsServerData) => {
        clientConnections.push({
            title: item.customerConnection,
            value: item.id,
            checked: true,
            data: item,
        });
        item.customers.forEach((customer) => {
            clients.push({
                title: customer.customer,
                value: customer.id,
                checked: true,
                data: customer,
            });
        });
    });
    return {
        id: data.id,
        name: data.name,
        surname: data.surname,
        email: data.email,
        userGroup: data.userGroup,
        active: data.active,
        phoneNumber: data.phoneNumber || '',
        roles: data.roles?.map((item) => ({ name: item })),
        chosenClientConnections: data.customerConnections,
        customerAdvisorNumber: data.customerAdvisorNumber,
        activated: data.activated,
        addNewCustomersAutomatically: data.addNewCustomersAutomatically,
        internalClientCards: [
            {
                id: '1',
                clientConnections,
                clients,
            },
        ],
    };
};
